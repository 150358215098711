import api from "../../domain/api";
import {
  GET_CUSTOMERS_STATED,
  GET_CUSTOMERS,
  GET_CUSTOMERS_ENDED,
  ADD_CUSTOMER_STATED,
  ADD_CUSTOMER,
  ADD_CUSTOMER_ENDED,
  EDIT_CUSTOMER_STATED,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_ENDED,
  GET_CUSTOMER_STATED,
  GET_CUSTOMER,
  GET_CUSTOMER_ENDED,
  GET_ALL_CUSTOMERS_STATED,
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_ENDED,
  GET_CUSTOMER_TRANSACTIONS_STATED,
  GET_CUSTOMER_TRANSACTIONS,
  GET_CUSTOMER_TRANSACTIONS_ENDED,
} from "../types/customer_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addCustomer = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CUSTOMER_STATED,
    });
    const { data } = await api.post(`/customers`, formData);
    dispatch({
      type: ADD_CUSTOMER,
      payload: data,
    });
    dispatch({
      type: ADD_CUSTOMER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_CUSTOMER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCustomers =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMERS_STATED,
      });

      const { data } = await api.get(
        `/customers?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_CUSTOMERS,
        payload: data,
      });
      dispatch({
        type: GET_CUSTOMERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getTransactionsByCustomer = (customer) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_TRANSACTIONS_STATED,
    });

    const { data } = await api.get(
      `/customers/transactions?customer=${customer}`
    );

    dispatch({
      type: GET_CUSTOMER_TRANSACTIONS,
      payload: data,
    });
    dispatch({
      type: GET_CUSTOMER_TRANSACTIONS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_TRANSACTIONS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_STATED,
    });
    const { data } = await api.get(`/customers/${id}`);

    dispatch({
      type: GET_CUSTOMER,
      payload: data,
    });
    dispatch({
      type: GET_CUSTOMER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCustomer = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CUSTOMER_STATED,
    });
    const { data } = await api.put(`/customers/${id}`, formData);
    dispatch({
      type: EDIT_CUSTOMER,
      payload: data,
    });
    dispatch({
      type: EDIT_CUSTOMER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_CUSTOMER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/customers/${id}`);
    dispatch(setAlert("Customer Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CUSTOMERS_STATED,
    });
    const { data } = await api.get(`/customers/all`);

    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_CUSTOMERS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CUSTOMERS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
