import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/jobcards_enum";
import { useCreateJobcard } from "../../shared/hooks/UseJobcard";
import { useSelectAllMechanic } from "../../shared/hooks/UseMechanic";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllBike } from "../../shared/hooks/UseBike";
import { useSelectAllTransaction } from "../../shared/hooks/UseTransaction";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import ReactModal from "react-modal";
const AddJobcard = ({}) => {
  let history = useHistory();
  const [jobcard, addData] = useCreateJobcard();
  const { add_jobcard_loading } = jobcard;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [mechanic] = useSelectAllMechanic();
  const [customer] = useSelectAllCustomer();
  const [bike] = useSelectAllBike();
  const [transaction] = useSelectAllTransaction();
  const [service] = useSelectAllService();
  const [product] = useSelectAllProduct();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  useEffect(() => {
    if (mechanic && mechanic.all_mechanics) {
      const newData = mechanic.all_mechanics.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, mechanic: newData });
    }
  }, [mechanic]);
  useEffect(() => {
    if (customer && customer.all_customers) {
      const newData = customer.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customer]);
  useEffect(() => {
    if (bike && bike.all_bikes) {
      const newData = bike.all_bikes.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, bike: newData });
    }
  }, [bike]);
  useEffect(() => {
    if (transaction && transaction.all_transactions) {
      const newData = transaction.all_transactions.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, transaction: newData });
    }
  }, [transaction]);
  useEffect(() => {
    if (service && service.all_services) {
      const newData = service.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [service]);
  useEffect(() => {
    if (product && product.all_products) {
      const newData = product.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [product]);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            {/* <div className="row">
              <div className="col-md-6">
                <label htmlFor=""> Select </label>
                <div
                  className="form-control"
                  onClick={() => {
                    setModal(true);
                    setModalData({ name: "Somthing", value: "Something" });
                  }}
                >
                  {" "}
                  Select Option{" "}
                </div>
              </div>
            </div> */}

            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_jobcard_loading}
            ></AddForm>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default AddJobcard;
