export const PAGE_TITLE = "Homepages";
export const PAGE_SINGLE_TITLE = "Homepage";
export const LINK_URL = "homepages";

export const inputFields = {
  home_banner: {
    type: "file",
    required: false,
    title: "Home Banner",
    inputType: "",
    options: "",
    field: "",
  },
  offer_banner: {
    type: "file",
    required: false,
    title: "Offer Banner",
    inputType: "",
    options: "",
    field: "",
  },
  footer_banner: {
    type: "file",
    required: false,
    title: "Footer Banner",
    inputType: "",
    options: "",
    field: "",
  },
  home_banner_hindi: {
    type: "file",
    required: false,
    title: "Home Banner Hindi",
    inputType: "",
    options: "",
    field: "",
  },
  offer_banner_hindi: {
    type: "file",
    required: false,
    title: "Offer Banner Hindi",
    inputType: "",
    options: "",
    field: "",
  },
  footer_banner_hindi: {
    type: "file",
    required: false,
    title: "Footer Banner Hindi",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  section_1_heading: "",
  section_1_heading_hindi: "",
  section_1_service_1: "",
  section_1_service_1_hindi: "",

  section_1_service_2: "",
  section_1_service_2_hindi: "",

  section_1_service_3: "",
  section_1_service_3_hindi: "",

  section_1_service_4: "",
  section_1_service_4_hindi: "",

  section_1_service_5: "",
  section_1_service_5_hindi: "",

  section_1_service_6: "",
  section_1_service_6_hindi: "",

  section_1_service_7: "",
  section_1_service_7_hindi: "",

  section_1_service_8: "",
  section_1_service_8_hindi: "",

  section_2_heading: "",
  section_2_heading_hindi: "",
  section_2_service_1: "",
  section_2_service_1_hindi: "",

  section_2_service_2: "",
  section_2_service_2_hindi: "",

  section_2_service_3: "",
  section_2_service_3_hindi: "",

  section_2_service_4: "",
  section_2_service_4_hindi: "",

  section_2_service_5: "",
  section_2_service_5_hindi: "",

  section_2_service_6: "",
  section_2_service_6_hindi: "",

  section_2_service_7: "",
  section_2_service_7_hindi: "",

  section_2_service_8: "",
  section_2_service_8_hindi: "",

  service_main_heading: "",
  service_heading: "",
  service_heading_hindi: "",
  parts_heading: "",
  parts_heading_hindi: "",
};

export const view_all_table = [
  {
    name: "Home Banner",
    value: "home_banner",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Offer Banner",
    value: "offer_banner",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Footer Banner",
    value: "footer_banner",
    related: "",
    field: "",
    image: false,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "section_1_heading",
    label: "Section 1 Heading",
    type: "string",
  },
];
