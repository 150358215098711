import {
  GET_BANNERLOGOS_STATED,
  GET_BANNERLOGOS,
  GET_BANNERLOGOS_ENDED,
  ADD_BANNERLOGO_STATED,
  ADD_BANNERLOGO,
  ADD_BANNERLOGO_ENDED,
  EDIT_BANNERLOGO_STATED,
  EDIT_BANNERLOGO,
  EDIT_BANNERLOGO_ENDED,
  GET_BANNERLOGO_STATED,
  GET_BANNERLOGO,
  GET_BANNERLOGO_ENDED,
  GET_ALL_BANNERLOGOS_STATED,
  GET_ALL_BANNERLOGOS,
  GET_ALL_BANNERLOGOS_ENDED,
} from "../types/bannerlogo_type";

const initialState = {
  bannerlogos_loading: true,
  bannerlogos: null,
  page: null,
  pages: null,
  total_bannerlogos: 0,

  bannerlogo: null,
  bannerlogo_loading: null,

  loading: true,

  bannerlogo_message: null,
  all_bannerlogos: null,
  all_bannerlogos_loading: null,
  add_bannerlogo_loading: true,
  edit_bannerlogo_loading: true,
};

export const bannerlogo_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BANNERLOGOS_STATED:
      return {
        ...state,
        bannerlogos: null,
        pages: null,
        page: null,
        total_bannerlogos: 0,
        bannerlogos_loading: true,
      };
    case GET_BANNERLOGOS:
      return {
        ...state,
        bannerlogos: payload.brandlogos,
        pages: payload.pages,
        page: payload.page,
        total_bannerlogos: payload.count,
      };
    case GET_BANNERLOGOS_ENDED:
      return {
        ...state,
        bannerlogos_loading: false,
      };
    case GET_ALL_BANNERLOGOS_STATED:
      return {
        ...state,
        all_bannerlogos_loading: true,
        all_bannerlogos: null,
      };
    case GET_ALL_BANNERLOGOS:
      return {
        ...state,
        all_bannerlogos: payload,
      };
    case GET_ALL_BANNERLOGOS_ENDED:
      return {
        ...state,
        all_bannerlogos_loading: false,
      };

    case ADD_BANNERLOGO_STATED:
      return {
        ...state,
        bannerlogo_message: null,
        add_bannerlogo_loading: true,
      };
    case ADD_BANNERLOGO:
      return {
        ...state,
        bannerlogo_message: payload,
      };
    case ADD_BANNERLOGO_ENDED:
      return {
        ...state,
        add_bannerlogo_loading: false,
      };
    case GET_BANNERLOGO_STATED:
      return {
        ...state,
        bannerlogo: null,
        bannerlogo_loading: true,
      };
    case GET_BANNERLOGO:
      return {
        ...state,
        bannerlogo: payload,
      };
    case GET_BANNERLOGO_ENDED:
      return {
        ...state,
        bannerlogo_loading: false,
      };
    case EDIT_BANNERLOGO_STATED:
      return {
        ...state,
        bannerlogo_message: null,
        edit_bannerlogo_loading: true,
      };
    case EDIT_BANNERLOGO:
      return {
        ...state,
        bannerlogo_message: payload,
      };
    case EDIT_BANNERLOGO_ENDED:
      return {
        ...state,
        edit_bannerlogo_loading: false,
      };

    default:
      return state;
  }
};
