import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/jobcards_enum";
import {
  useUpdateJobcard,
  useSingleJobcard,
} from "../../shared/hooks/UseJobcard";
import { useSelectAllMechanic } from "../../shared/hooks/UseMechanic";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllBike } from "../../shared/hooks/UseBike";
import { useSelectAllTransaction } from "../../shared/hooks/UseTransaction";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import { useSelectAllCoupon } from "../../shared/hooks/UseCoupon";

const EditJobcard = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleJobcard(match.params.id);
  const [updateData] = useUpdateJobcard();
  const { jobcard_loading, jobcard, edit_jobcard_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [mechanic] = useSelectAllMechanic();
  const [customer] = useSelectAllCustomer();
  const [bike] = useSelectAllBike();
  const [transaction] = useSelectAllTransaction();
  const [service] = useSelectAllService();
  const [product] = useSelectAllProduct();
  const [coupon] = useSelectAllCoupon();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(jobcard._id, data);
    history.push(`/${LINK_URL}/${jobcard._id}/view`);
  };

  useEffect(() => {
    if (mechanic && mechanic.all_mechanics) {
      const newData = mechanic.all_mechanics.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, mechanic: newData });
    }
  }, [mechanic]);
  useEffect(() => {
    if (customer && customer.all_customers) {
      const newData = customer.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customer]);
  useEffect(() => {
    if (bike && bike.all_bikes) {
      const newData = bike.all_bikes.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, bike: newData });
    }
  }, [bike]);
  useEffect(() => {
    if (transaction && transaction.all_transactions) {
      const newData = transaction.all_transactions.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, transaction: newData });
    }
  }, [transaction]);
  useEffect(() => {
    if (service && service.all_services) {
      const newData = service.all_services.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, services: newData });
    }
  }, [service]);
  useEffect(() => {
    if (product && product.all_products) {
      const newData = product.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, products: newData });
    }
  }, [product]);
  useEffect(() => {
    if (coupon && coupon.all_coupons) {
      const newData = coupon.all_coupons.map((item) => {
        return { label: item.code, value: item._id };
      });

      setDropdownOptions({ ...dropdownOptions, coupon: newData });
    }
  }, [product]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!jobcard_loading ? (
              jobcard && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${jobcard._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={jobcard}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_jobcard_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobcard;
