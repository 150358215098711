export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
  },

  {
    label: "Job Cards",
    link: "/jobcards",
  },
  {
    label: "Products",
    link: "#",
    menu: [
      {
        label: "Bike Brands",
        link: "/brands",
      },
      {
        label: "Bike Models",
        link: "/bikes",
      },
      {
        label: "Category",
        link: "/categories",
      },
      {
        label: "Products",
        link: "/products",
      },
      {
        label: "Services",
        link: "/services",
      },
      {
        label: "Coupons",
        link: "/coupons",
      },
    ],
  },
  {
    label: "Customers",
    link: "/customers",
  },
  {
    label: "Mechanics",
    link: "/mechanics",
  },

  {
    label: "Master",
    link: "#",
    menu: [
      {
        label: "Locations",
        link: "/locations",
      },

      {
        label: "Homepage",
        link: "/homepages",
      },
      {
        label: "Brand Logos",
        link: "/bannerlogos",
      },
      // {
      //   label: "Top Banners",
      //   link: "/banners",
      // },
      // {
      //   label: "Offer Banners",
      //   link: "/offers",
      // },
      // {
      //   label: "Footer Banners",
      //   link: "/footers",
      // },
      // {
      //   label: "Complaints",
      //   link: "/complaints",
      // },
    ],
  },
];
