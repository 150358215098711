import {
  GET_DASHBOARD,
  GET_DASHBOARD_STATED,
  GET_DASHBOARD_ENDED,
} from "../types/dashboard_type";

const initialState = {
  dashboard_loading: true,
  dashboard: null,
};

export const dashboard_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARD_STATED:
      return {
        ...state,
        dashboard_loading: true,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case GET_DASHBOARD_ENDED:
      return {
        ...state,
        dashboard_loading: false,
      };

    default:
      return state;
  }
};
