import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/homepages_enum";
import {
  useCreateHomepage,
  useAllHomepages,
} from "../../shared/hooks/UseHomepage";
// import { useSelectAllHomepage } from "../../shared/hooks/UseHomepage";

const EditHomepage = ({ match }) => {
  let history = useHistory();
  const [single_data] = useAllHomepages();
  const [homepage1, addData] = useCreateHomepage();
  const { add_homepage_loading } = homepage1;
  const { homepage_loading, homepage, edit_homepage_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [homepage1] = useSelectAllHomepage();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/`);
  };

  // useEffect(() => {
  //   if (homepage1 && homepage1.all_homepages) {
  //     const newData = homepage1.all_homepages.map((item) => {
  //       return { label: item.homepage_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_homepage: newData });
  //   }
  // }, [homepage1]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="/"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!homepage_loading ? (
              homepage && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">Homepage</h4>
                    </div>
                  </div>
                  <AddForm
                    data={homepage}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_homepage_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHomepage;
