import api from "../../domain/api";
import {
  GET_JOBCARDS_STATED,
  GET_JOBCARDS,
  GET_JOBCARDS_ENDED,
  ADD_JOBCARD_STATED,
  ADD_JOBCARD,
  ADD_JOBCARD_ENDED,
  EDIT_JOBCARD_STATED,
  EDIT_JOBCARD,
  EDIT_JOBCARD_ENDED,
  GET_JOBCARD_STATED,
  GET_JOBCARD,
  GET_JOBCARD_ENDED,
  GET_ALL_JOBCARDS_STATED,
  GET_ALL_JOBCARDS,
  GET_ALL_JOBCARDS_ENDED,
} from "../types/jobcard_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addJobcard = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_JOBCARD_STATED,
    });
    const { data } = await api.post(`/jobcards`, formData);
    dispatch({
      type: ADD_JOBCARD,
      payload: data,
    });
    dispatch({
      type: ADD_JOBCARD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_JOBCARD_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getJobcards =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_JOBCARDS_STATED,
      });

      const { data } = await api.get(
        `/jobcards?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_JOBCARDS,
        payload: data,
      });
      dispatch({
        type: GET_JOBCARDS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_JOBCARDS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getJobcard = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBCARD_STATED,
    });
    const { data } = await api.get(`/jobcards/${id}`);

    dispatch({
      type: GET_JOBCARD,
      payload: data,
    });
    dispatch({
      type: GET_JOBCARD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_JOBCARD_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editJobcard = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_JOBCARD_STATED,
    });
    const { data } = await api.put(`/jobcards/${id}`, formData);
    dispatch({
      type: EDIT_JOBCARD,
      payload: data,
    });
    dispatch({
      type: EDIT_JOBCARD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_JOBCARD_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteJobcard = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/jobcards/${id}`);
    dispatch(setAlert("Jobcard Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllJobcards = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_JOBCARDS_STATED,
    });
    const { data } = await api.get(`/jobcards/all`);

    dispatch({
      type: GET_ALL_JOBCARDS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_JOBCARDS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_JOBCARDS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
