import { useState, useEffect, useCallback } from "react";
import _debounce from "lodash/debounce";

import { useSelector, useDispatch } from "react-redux";
import {
  addBike,
  getBikes,
  getBike,
  editBike,
  deleteBike,
  getAllBikes,
} from "../../store/actions/bike_action";

// Get All Data
export const useAllBikes = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bike);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteBike(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getBikes({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleBike = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bike);
  useEffect(() => {
    dispatch(getBike(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateBike = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bike);
  const addData = async (data) => {
    await dispatch(addBike(data));
  };
  return [data, addData];
};
export const useUpdateBike = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.bike);
  const updateData = async (id, data) => {
    await dispatch(editBike(id, data));
  };
  return [updateData];
};

export const useSelectAllBike = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bike);
  useEffect(() => {
    dispatch(getAllBikes());
  }, []);
  return [data];
};
