export const PAGE_TITLE = "Footers";
export const PAGE_SINGLE_TITLE = "Footer";
export const LINK_URL = "footers";
export const inputFields = {
  title: {
    type: "string",
    required: true,
    title: "Title",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
  },
  image_hindi: {
    type: "file",
    required: false,
    title: "Image (Hindi)",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
  },
  description_hindi: {
    type: "html",
    required: false,
    title: "Description (Hindi)",
  },
};
export const initialValues = {
  title: "",
  description: "",
  description_hindi: "",
};

export const view_all_table = [
  {
    name: "Title",
    value: "title",
    related: false,
    field: "",
    image: false,
  },
  {
    name: "Image",
    value: "image",
    related: "",
    field: "",
    image: true,
  },
  {
    name: "Image (Hindi)",
    value: "image_hindi",
    related: "",
    field: "",
    image: true,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
