export const GET_BANNERLOGOS_STATED = "GET_BANNERLOGOS_STATED";
export const GET_BANNERLOGOS = "GET_BANNERLOGOS";
export const GET_BANNERLOGOS_ENDED = "GET_BANNERLOGOS_ENDED";
export const ADD_BANNERLOGO_STATED = "ADD_BANNERLOGO_STARTED";
export const ADD_BANNERLOGO = "ADD_BANNERLOGO";
export const ADD_BANNERLOGO_ENDED = "ADD_BANNERLOGO_ENDED";
export const EDIT_BANNERLOGO_STATED = "EDIT_BANNERLOGO_STATED";
export const EDIT_BANNERLOGO = "EDIT_BANNERLOGO";
export const EDIT_BANNERLOGO_ENDED = "EDIT_BANNERLOGO_ENDED";
export const GET_BANNERLOGO = "GET_BANNERLOGO";
export const GET_BANNERLOGO_STATED = "GET_BANNERLOGO_STATED";
export const GET_BANNERLOGO_ENDED = "GET_BANNERLOGO_ENDED";
export const RESET_BANNERLOGO = "RESET_BANNERLOGO";
export const ERROR_BANNERLOGO = "ERROR_BANNERLOGO";
export const GET_ALL_BANNERLOGOS_STATED = "GET_ALL_BANNERLOGOS_STATED";
export const GET_ALL_BANNERLOGOS = "GET_ALL_BANNERLOGOS";
export const GET_ALL_BANNERLOGOS_ENDED = "GET_ALL_BANNERLOGOS_ENDED";
