import api from "../../domain/api";
import {
  GET_LOCATIONS_STATED,
  GET_LOCATIONS,
  GET_LOCATIONS_ENDED,
  ADD_LOCATION_STATED,
  ADD_LOCATION,
  ADD_LOCATION_ENDED,
  EDIT_LOCATION_STATED,
  EDIT_LOCATION,
  EDIT_LOCATION_ENDED,
  GET_LOCATION_STATED,
  GET_LOCATION,
  GET_LOCATION_ENDED,
  GET_ALL_LOCATIONS_STATED,
  GET_ALL_LOCATIONS,
  GET_ALL_LOCATIONS_ENDED,
} from "../types/location_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addLocation = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_LOCATION_STATED,
    });
    const { data } = await api.post(`/locations`, formData);
    dispatch({
      type: ADD_LOCATION,
      payload: data,
    });
    dispatch({
      type: ADD_LOCATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_LOCATION_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getLocations =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_LOCATIONS_STATED,
      });

      const { data } = await api.get(
        `/locations?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_LOCATIONS,
        payload: data,
      });
      dispatch({
        type: GET_LOCATIONS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_LOCATIONS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getLocation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LOCATION_STATED,
    });
    const { data } = await api.get(`/locations/${id}`);

    dispatch({
      type: GET_LOCATION,
      payload: data,
    });
    dispatch({
      type: GET_LOCATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_LOCATION_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editLocation = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_LOCATION_STATED,
    });
    const { data } = await api.put(`/locations/${id}`, formData);
    dispatch({
      type: EDIT_LOCATION,
      payload: data,
    });
    dispatch({
      type: EDIT_LOCATION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_LOCATION_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteLocation = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/locations/${id}`);
    dispatch(setAlert("Location Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllLocations = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_LOCATIONS_STATED,
    });
    const { data } = await api.get(`/locations/all`);

    dispatch({
      type: GET_ALL_LOCATIONS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_LOCATIONS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_LOCATIONS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
