import api from "../../domain/api";
import {
  GET_BANNERLOGOS_STATED,
  GET_BANNERLOGOS,
  GET_BANNERLOGOS_ENDED,
  ADD_BANNERLOGO_STATED,
  ADD_BANNERLOGO,
  ADD_BANNERLOGO_ENDED,
  EDIT_BANNERLOGO_STATED,
  EDIT_BANNERLOGO,
  EDIT_BANNERLOGO_ENDED,
  GET_BANNERLOGO_STATED,
  GET_BANNERLOGO,
  GET_BANNERLOGO_ENDED,
  GET_ALL_BANNERLOGOS_STATED,
  GET_ALL_BANNERLOGOS,
  GET_ALL_BANNERLOGOS_ENDED,
} from "../types/bannerlogo_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addBannerlogo = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BANNERLOGO_STATED,
    });
    const { data } = await api.post(`/bannerlogos`, formData);
    dispatch({
      type: ADD_BANNERLOGO,
      payload: data,
    });
    dispatch({
      type: ADD_BANNERLOGO_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_BANNERLOGO_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getBannerlogos =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BANNERLOGOS_STATED,
      });

      const { data } = await api.get(
        `/bannerlogos?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_BANNERLOGOS,
        payload: data,
      });
      dispatch({
        type: GET_BANNERLOGOS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_BANNERLOGOS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getBannerlogo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BANNERLOGO_STATED,
    });
    const { data } = await api.get(`/bannerlogos/${id}`);

    dispatch({
      type: GET_BANNERLOGO,
      payload: data,
    });
    dispatch({
      type: GET_BANNERLOGO_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_BANNERLOGO_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editBannerlogo = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BANNERLOGO_STATED,
    });
    const { data } = await api.put(`/bannerlogos/${id}`, formData);
    dispatch({
      type: EDIT_BANNERLOGO,
      payload: data,
    });
    dispatch({
      type: EDIT_BANNERLOGO_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_BANNERLOGO_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBannerlogo = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/bannerlogos/${id}`);
    dispatch(setAlert("Bannerlogo Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllBannerlogos = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BANNERLOGOS_STATED,
    });
    const { data } = await api.get(`/bannerlogos/all`);

    dispatch({
      type: GET_ALL_BANNERLOGOS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_BANNERLOGOS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BANNERLOGOS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
