export const GET_BIKES_STATED = "GET_BIKES_STATED";
export const GET_BIKES = "GET_BIKES";
export const GET_BIKES_ENDED = "GET_BIKES_ENDED";
export const ADD_BIKE_STATED = "ADD_BIKE_STARTED";
export const ADD_BIKE = "ADD_BIKE";
export const ADD_BIKE_ENDED = "ADD_BIKE_ENDED";
export const EDIT_BIKE_STATED = "EDIT_BIKE_STATED";
export const EDIT_BIKE = "EDIT_BIKE";
export const EDIT_BIKE_ENDED = "EDIT_BIKE_ENDED";
export const GET_BIKE = "GET_BIKE";
export const GET_BIKE_STATED = "GET_BIKE_STATED";
export const GET_BIKE_ENDED = "GET_BIKE_ENDED";
export const RESET_BIKE = "RESET_BIKE";
export const ERROR_BIKE = "ERROR_BIKE";
export const GET_ALL_BIKES_STATED = "GET_ALL_BIKES_STATED";
export const GET_ALL_BIKES = "GET_ALL_BIKES";
export const GET_ALL_BIKES_ENDED = "GET_ALL_BIKES_ENDED";
