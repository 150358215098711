import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addBannerlogo,
  getBannerlogos,
  getBannerlogo,
  editBannerlogo,
  deleteBannerlogo,
  getAllBannerlogos,
} from "../../store/actions/bannerlogo_action";

import _debounce from "lodash/debounce";

// Get All Data
export const useAllBannerlogos = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bannerlogo);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteBannerlogo(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getBannerlogos({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleBannerlogo = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bannerlogo);
  useEffect(() => {
    dispatch(getBannerlogo(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateBannerlogo = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bannerlogo);
  const addData = async (data) => {
    await dispatch(addBannerlogo(data));
  };
  return [data, addData];
};
export const useUpdateBannerlogo = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.bannerlogo);
  const updateData = async (id, data) => {
    await dispatch(editBannerlogo(id, data));
  };
  return [updateData];
};

export const useSelectAllBannerlogo = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bannerlogo);
  useEffect(() => {
    dispatch(getAllBannerlogos());
  }, []);
  return [data];
};
