export const GET_TRANSACTIONS_STATED = "GET_TRANSACTIONS_STATED";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_ENDED = "GET_TRANSACTIONS_ENDED";
export const ADD_TRANSACTION_STATED = "ADD_TRANSACTION_STARTED";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_ENDED = "ADD_TRANSACTION_ENDED";
export const EDIT_TRANSACTION_STATED = "EDIT_TRANSACTION_STATED";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const EDIT_TRANSACTION_ENDED = "EDIT_TRANSACTION_ENDED";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_STATED = "GET_TRANSACTION_STATED";
export const GET_TRANSACTION_ENDED = "GET_TRANSACTION_ENDED";
export const RESET_TRANSACTION = "RESET_TRANSACTION";
export const ERROR_TRANSACTION = "ERROR_TRANSACTION";
export const GET_ALL_TRANSACTIONS_STATED = "GET_ALL_TRANSACTIONS_STATED";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_ENDED = "GET_ALL_TRANSACTIONS_ENDED";
