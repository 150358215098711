import {
  GET_BIKES_STATED,
  GET_BIKES,
  GET_BIKES_ENDED,
  ADD_BIKE_STATED,
  ADD_BIKE,
  ADD_BIKE_ENDED,
  EDIT_BIKE_STATED,
  EDIT_BIKE,
  EDIT_BIKE_ENDED,
  GET_BIKE_STATED,
  GET_BIKE,
  GET_BIKE_ENDED,
  GET_ALL_BIKES_STATED,
  GET_ALL_BIKES,
  GET_ALL_BIKES_ENDED
} from "../types/bike_type";

const initialState = {
  bikes_loading: true,
  bikes: null,
  page: null,
  pages: null,
  total_bikes: 0,

  bike: null,
  bike_loading: null,

  loading: true,

  bike_message: null,
  all_bikes: null,
  all_bikes_loading: null,
  add_bike_loading: true,
  edit_bike_loading: true
};

export const bike_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BIKES_STATED:
      return {
        ...state,
        bikes: null,
        pages: null,
        page: null,
        total_bikes: 0,
        bikes_loading: true
      };
    case GET_BIKES:
      return {
        ...state,
        bikes: payload.bikes,
        pages: payload.pages,
        page: payload.page,
        total_bikes: payload.count
      };
    case GET_BIKES_ENDED:
      return {
        ...state,
        bikes_loading: false
      };
    case GET_ALL_BIKES_STATED:
      return {
        ...state,
        all_bikes_loading: true,
        all_bikes: null
      };
    case GET_ALL_BIKES:
      return {
        ...state,
        all_bikes: payload
      };
    case GET_ALL_BIKES_ENDED:
      return {
        ...state,
        all_bikes_loading: false
      };

    case ADD_BIKE_STATED:
      return {
        ...state,
        bike_message: null,
        add_bike_loading: true
      };
    case ADD_BIKE:
      return {
        ...state,
        bike_message: payload
      };
    case ADD_BIKE_ENDED:
      return {
        ...state,
        add_bike_loading: false
      };
    case GET_BIKE_STATED:
      return {
        ...state,
        bike: null,
        bike_loading: true
      };
    case GET_BIKE:
      return {
        ...state,
        bike: payload
      };
    case GET_BIKE_ENDED:
      return {
        ...state,
        bike_loading: false
      };
    case EDIT_BIKE_STATED:
      return {
        ...state,
        bike_message: null,
        edit_bike_loading: true
      };
    case EDIT_BIKE:
      return {
        ...state,
        bike_message: payload
      };
    case EDIT_BIKE_ENDED:
      return {
        ...state,
        edit_bike_loading: false
      };

    default:
      return state;
  }
};
