import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/complaints_enum";
import { useCreateComplaint } from "../../shared/hooks/UseComplaint";
import { useSelectAllJobcard } from "../../shared/hooks/UseJobcard";
import { useSelectAllCustomer } from "../../shared/hooks/UseCustomer";
import { useSelectAllMechanic } from "../../shared/hooks/UseMechanic";

const AddComplaint = ({}) => {
  let history = useHistory();
  const [complaint, addData] = useCreateComplaint();
  const { add_complaint_loading } = complaint;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [jobcard] = useSelectAllJobcard();
  const [customer] = useSelectAllCustomer();
  const [mechanic] = useSelectAllMechanic();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  useEffect(() => {
    if (jobcard && jobcard.all_jobcards) {
      const newData = jobcard.all_jobcards.map((item) => {
        return { label: item.jobcard_id, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, jobcard: newData });
    }
  }, [jobcard]);
  useEffect(() => {
    if (customer && customer.all_customers) {
      const newData = customer.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customer]);
  useEffect(() => {
    if (mechanic && mechanic.all_mechanics) {
      const newData = mechanic.all_mechanics.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, mechanic: newData });
    }
  }, [mechanic]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_complaint_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddComplaint;
