import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/bannerlogos_enum";
import { useCreateBannerlogo } from "../../shared/hooks/UseBannerlogo";
// import { useSelectAllBannerlogo } from "../../shared/hooks/UseBannerlogo";

const AddBannerlogo = ({}) => {
  let history = useHistory();
  const [bannerlogo, addData] = useCreateBannerlogo();
  const { add_bannerlogo_loading } = bannerlogo;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  // const [bannerlogo] = useSelectAllBannerlogo();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  // useEffect(() => {
  //   if (bannerlogo && bannerlogo.all_bannerlogos) {
  //     const newData = bannerlogo.all_bannerlogos.map((item) => {
  //       return { label: item.bannerlogo_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_bannerlogo: newData });
  //   }
  // }, [bannerlogo]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_bannerlogo_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBannerlogo;
