export const PAGE_TITLE = "Transactions";
export const PAGE_SINGLE_TITLE = "Transaction";
export const LINK_URL = "transactions";
export const inputFields = {
  amount: {
    type: "string",
    required: true,
    title: "Amount",
    inputType: "number",
    options: "",
    field: "",
  },
  jobcard: {
    type: "select",
    required: false,
    title: "Jobcard",
    inputType: "text",
    options: "",
    field: "jobcard_id",
  },
  payment_method: {
    type: "select",
    required: true,
    title: "Payment Method",
    inputType: "",
    options: ["CASH", "UPI", "CARD", "ONLINE"],
    field: "",
  },
  gst: {
    type: "string",
    required: false,
    title: "GST",
    inputType: "number",
    options: "",
    field: "",
  },
  is_collected: {
    type: "checkbox",
    required: false,
    title: "Is Collected?",
    inputType: "",
    options: "",
    field: "",
  },
  customer: {
    type: "related",
    required: false,
    title: "Cusotmer",
    inputType: "",
    options: "",
    field: "name",
  },
  mechanic: {
    type: "related",
    required: false,
    title: "Mechanic",
    inputType: "",
    options: "",
    field: "name",
  },
};
export const initialValues = {
  amount: "",
  jobcard: "",
  payment_method: "",
  gst: "",
  is_collected: false,
  customer: "",
  mechanic: "",
};

export const view_all_table = [
  {
    name: "Amount",
    value: "amount",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "Jobcard",
    value: "jobcard",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "Payment Method",
    value: "payment_method",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "GST",
    value: "gst",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "Cusotmer",
    value: "customer",
    related: true,
    field: "name",
    image: "",
  },
  {
    name: "Mechanic",
    value: "mechanic",
    related: true,
    field: "name",
    image: "",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "amount",
    label: "Amount",
    type: "string",
  },
  {
    field: "jobcard",
    label: "Jobcard",
    type: "string",
  },
  {
    field: "payment_method",
    label: "Payment Method",
    type: "string",
  },
  {
    field: "gst",
    label: "GST",
    type: "string",
  },
];
