import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _debounce from "lodash/debounce";

import {
  addCategory,
  getCategorys,
  getCategory,
  editCategory,
  deleteCategory,
  getAllCategorys,
} from "../../store/actions/category_action";

// Get All Data
export const useAllCategorys = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteCategory(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getCategorys({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleCategory = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(getCategory(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateCategory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  const addData = async (data) => {
    await dispatch(addCategory(data));
  };
  return [data, addData];
};
export const useUpdateCategory = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.category);
  const updateData = async (id, data) => {
    await dispatch(editCategory(id, data));
  };
  return [updateData];
};

export const useSelectAllCategory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(getAllCategorys());
  }, []);
  return [data];
};
