export const GET_BRANDS_STATED = "GET_BRANDS_STATED";
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_ENDED = "GET_BRANDS_ENDED";
export const ADD_BRAND_STATED = "ADD_BRAND_STARTED";
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_ENDED = "ADD_BRAND_ENDED";
export const EDIT_BRAND_STATED = "EDIT_BRAND_STATED";
export const EDIT_BRAND = "EDIT_BRAND";
export const EDIT_BRAND_ENDED = "EDIT_BRAND_ENDED";
export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_STATED = "GET_BRAND_STATED";
export const GET_BRAND_ENDED = "GET_BRAND_ENDED";
export const RESET_BRAND = "RESET_BRAND";
export const ERROR_BRAND = "ERROR_BRAND";
export const GET_ALL_BRANDS_STATED = "GET_ALL_BRANDS_STATED";
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const GET_ALL_BRANDS_ENDED = "GET_ALL_BRANDS_ENDED";
