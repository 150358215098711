import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/bannerlogos_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleBannerlogo } from "../../shared/hooks/UseBannerlogo";
const ViewBannerlogo = ({ match }) => {
  const [data] = useSingleBannerlogo(match.params.id);
  const { bannerlogo_loading, bannerlogo } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!bannerlogo_loading ? (
          bannerlogo && (
            <SingleView
              data={bannerlogo}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={bannerlogo._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBannerlogo;
