export const PAGE_TITLE = "Coupons";
export const PAGE_SINGLE_TITLE = "Coupon";
export const LINK_URL = "coupons";
export const inputFields = {
  code: {
    type: "string",
    required: true,
    title: "Code",
    inputType: "text",
    options: "",
  },
  discount_type: {
    type: "select",
    required: true,
    title: "Discount Type",
    inputType: "text",
    options: ["PERCENTAGE", "FIXED"],
  },
  max_discount: {
    type: "string",
    required: true,
    title: "Max Discount",
    inputType: "number",
    options: "",
  },
  min_cart_value: {
    type: "string",
    required: true,
    title: "Min Cart Value",
    inputType: "number",
    options: "",
  },
  discount: {
    type: "string",
    required: true,
    title: "Discount",
    inputType: "number",
    options: "",
  },
};
export const initialValues = {
  code: "",
  discount_type: "",
  max_discount: "",
  min_cart_value: "",
  discount: "",
};

export const view_all_table = [
  {
    name: "Code",
    value: "code",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Discount Type",
    value: "discount_type",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Max Discount",
    value: "max_discount",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Min Cart Value",
    value: "min_cart_value",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Discount",
    value: "discount",
    related: "",
    field: "",
    image: false,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "code",
    field: "code",
    label: "Code",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "discount_type",
    field: "discount_type",
    label: "Discount Type",
    type: "select",
    search_type: "search",
    inputType: "text",
    condition: "",
    options: ["FIXED", "PERCENTAGE"],
  },

  {
    id: "max_discount",
    field: "max_discount",
    label: "Max Discount",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
