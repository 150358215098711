export const GET_MECHANICS_STATED = "GET_MECHANICS_STATED";
export const GET_MECHANICS = "GET_MECHANICS";
export const GET_MECHANICS_ENDED = "GET_MECHANICS_ENDED";
export const ADD_MECHANIC_STATED = "ADD_MECHANIC_STARTED";
export const ADD_MECHANIC = "ADD_MECHANIC";
export const ADD_MECHANIC_ENDED = "ADD_MECHANIC_ENDED";
export const EDIT_MECHANIC_STATED = "EDIT_MECHANIC_STATED";
export const EDIT_MECHANIC = "EDIT_MECHANIC";
export const EDIT_MECHANIC_ENDED = "EDIT_MECHANIC_ENDED";
export const GET_MECHANIC = "GET_MECHANIC";
export const GET_MECHANIC_STATED = "GET_MECHANIC_STATED";
export const GET_MECHANIC_ENDED = "GET_MECHANIC_ENDED";
export const RESET_MECHANIC = "RESET_MECHANIC";
export const ERROR_MECHANIC = "ERROR_MECHANIC";
export const GET_ALL_MECHANICS_STATED = "GET_ALL_MECHANICS_STATED";
export const GET_ALL_MECHANICS = "GET_ALL_MECHANICS";
export const GET_ALL_MECHANICS_ENDED = "GET_ALL_MECHANICS_ENDED";
