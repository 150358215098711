export const PAGE_TITLE = "Complaints";
export const PAGE_SINGLE_TITLE = "Complaint";
export const LINK_URL = "complaints";
export const inputFields = {
  jobcard: {
    type: "related",
    required: true,
    title: "Jobcard",
    inputType: "",
    options: "",
    field: "jobcard_id",
  },
  customer: {
    type: "related",
    required: false,
    title: "Customer",
    inputType: "",
    options: "",
    field: "name",
  },
  status: {
    type: "select",
    required: true,
    title: "Status",
    inputType: "",
    options: ["OPENED", "TECHNICIAN ASSIGNED", "IN PROGRESS", "CLOSED"],
    field: "",
  },
  message: {
    type: "string",
    required: true,
    title: "Message",
    inputType: "",
    options: "",
    field: "",
  },
  mechanic: {
    type: "related",
    required: true,
    title: "Mechanic",
    inputType: "",
    options: "",
    field: "name",
  },
  mechanic_assigned: {
    type: "checkbox",
    required: false,
    title: "is Mechanic Assigned",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  jobcard: "",
  customer: "",
  status: "",
  message: "",
  mechanic: "",
  mechanic_assigned: "",
};

export const view_all_table = [
  {
    name: "Jobcard",
    value: "jobcard",
    related: true,
    field: "jobcard_id",
    image: "",
  },
  {
    name: "Customer",
    value: "customer",
    related: true,
    field: "name",
    image: "",
  },
  {
    name: "Status",
    value: "status",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "Message",
    value: "message",
    related: "",
    field: "",
    image: "",
  },
  {
    name: "Mechanic",
    value: "mechanic",
    related: true,
    field: "name",
    image: "",
  },
  {
    name: "Mechanic_assigned",
    value: "mechanic_assigned",
    related: "",
    field: "",
    image: "",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
