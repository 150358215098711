import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { bike_reducer } from "./bike_reducer";
import { brand_reducer } from "./brand_reducer";
import { category_reducer } from "./category_reducer";
import { coupon_reducer } from "./coupon_reducer";
import { homepage_reducer } from "./homepage_reducer";
import { jobcard_reducer } from "./jobcard_reducer";
import { location_reducer } from "./location_reducer";
import { product_reducer } from "./product_reducer";
import { service_reducer } from "./service_reducer";
import { transaction_reducer } from "./transaction_reducer";
import { customer_reducer } from "./customer_reducer";
import { mechanic_reducer } from "./mechanic_reducer";
import { complaint_reducer } from "./complaint_reducer";
import { dashboard_reducer } from "./dashboard_reducer";
import { banner_reducer } from "./banner_reducer";
import { offer_reducer } from "./offer_reducer";
import { footer_reducer } from "./footer_reducer";
import { bannerlogo_reducer } from "./bannerlogo_reducer";

export default combineReducers({
  alert,
  auth,
  bike: bike_reducer,
  brand: brand_reducer,
  category: category_reducer,
  coupon: coupon_reducer,
  homepage: homepage_reducer,
  jobcard: jobcard_reducer,
  location: location_reducer,
  product: product_reducer,
  service: service_reducer,
  transaction: transaction_reducer,
  customer: customer_reducer,
  mechanic: mechanic_reducer,
  complaint: complaint_reducer,
  dashboard: dashboard_reducer,
  banner: banner_reducer,
  offer: offer_reducer,
  footer: footer_reducer,
  bannerlogo: bannerlogo_reducer,
});
