export const PAGE_TITLE = "Jobcards";
export const PAGE_SINGLE_TITLE = "Jobcard";
export const LINK_URL = "jobcards";
export const inputFields = {
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    inputType: "text",
    options: "",
    field: "name",
  },

  mechanic: {
    type: "related",
    required: false,
    title: "Mechanic",
    inputType: "text",
    options: ["PERCANTAGE", "FIXED"],
    field: "name",
  },
  mechanic_assigned: {
    type: "checkbox",
    required: false,
    title: "is Mechanic Assigned",
    inputType: "number",
    options: "",
    field: "",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "text",
    options: "",
    field: "",
  },
  booking_date: {
    type: "string",
    required: true,
    title: "Booking Date",
    inputType: "date",
    options: "",
    field: "",
  },
  booking_time: {
    type: "string",
    required: true,
    title: "Booking Slot",
  },
  bike: {
    type: "related",
    required: true,
    title: "Bike",
    inputType: "text",
    options: "",
    field: "name",
  },

  bike_year: {
    type: "string",
    required: true,
    title: "Bike Year",
    inputType: "",
    options: "",
    field: "",
  },
  bike_no: {
    type: "string",
    required: true,
    title: "Bike No",
    inputType: "",
    options: "",
    field: "",
  },
  engine_type: {
    type: "select",
    required: true,
    title: "Engine Type",
    inputType: "",
    options: ["BS4", "BS6"],
    field: "",
  },
  address: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "",
    options: "",
    field: "",
  },
  landmark: {
    type: "string",
    required: true,
    title: "Address",
    inputType: "",
    options: "",
    field: "",
  },
  city: {
    type: "string",
    required: false,
    title: "City",
    inputType: "",
    options: "",
    field: "",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
    inputType: "",
    options: "",
    field: "",
  },
  pin: {
    type: "string",
    required: true,
    title: "Pin",
    inputType: "",
    options: "",
    field: "",
  },
  requested_services: {
    type: "string",
    required: false,
    title: "Requested Services",
    inputType: "",
    options: "",
    field: "",
  },
  latitude: {
    type: "string",
    required: false,
    title: "Latitude",
    inputType: "",
    options: "",
    field: "",
  },
  longitude: {
    type: "string",
    required: false,
    title: "Longitude",
    inputType: "",
    options: "",
    field: "",
  },
  latitude_technician: {
    type: "string",
    required: false,
    title: "Technician Latitude",
    inputType: "number",
    options: "",
    field: "",
  },
  longitude_technician: {
    type: "string",
    required: false,
    title: "Technician Longitude",
    inputType: "number",
    options: "",
    field: "",
  },
  coupon: {
    type: "related",
    required: false,
    title: "Coupon",
    inputType: "",
    options: "",
    field: "code",
  },

  sub_total: {
    type: "string",
    required: false,
    title: "Sub Total",
    inputType: "",
    options: "",
    field: "",
  },
  tax: {
    type: "string",
    required: false,
    title: "Tax",
    inputType: "",
    options: "",
    field: "",
  },
  discount: {
    type: "string",
    required: false,
    title: "Discount",
    inputType: "",
    options: "",
    field: "",
  },
  total: {
    type: "string",
    required: false,
    title: "Total",
    inputType: "",
    options: "",
    field: "",
  },
  is_coupon_applied: {
    type: "checkbox",
    required: false,
    title: "Is Coupon Applied",
    inputType: "",
    options: "",
    field: "",
  },
  jobcard_open_date: {
    type: "string",
    required: false,
    title: "Jobcard Open Date",
    inputType: "date",
    options: "",
    field: "",
  },
  jobcard_close_date: {
    type: "string",
    required: false,
    title: "Jobcard Close Date",
    inputType: "date",
    options: "",
    field: "",
  },
  opening_image: {
    type: "file",
    required: false,
    title: "Opening Image",
    inputType: "",
    options: "",
    field: "",
  },
  opening_image_time: {
    type: "string",
    required: false,
    title: "Start Service Time",
    inputType: "",
    options: "",
    field: "",
  },
  closing_image: {
    type: "file",
    required: false,
    title: "Closing Image",
    inputType: "",
    options: "",
    field: "",
  },
  closing_image_time: {
    type: "string",
    required: false,
    title: "Close Service Time",
    inputType: "",
    options: "",
    field: "",
  },
  status: {
    type: "select",
    required: false,
    title: "Status",
    inputType: "",
    options: [
      "OPENED",
      "TECHNICIAN ASSIGNED",
      "TECHNICIAN IN ROUTE",
      "TECHNICIAN ARRIVED",
      "ESTIMATE SENT",
      "ESTIMATE APPROVED",
      "ESTIMATE REJECTED",
      "IN PROGRESS",
      "CLOSED",
      "CANCELLED",
    ],
    field: "",
  },
  estimate_status: {
    type: "select",
    required: false,
    title: "Estimate Status",
    inputType: "",
    options: ["PENDING", "SENT", "APPROVED", "REJECTED"],
    field: "",
  },
  is_paid: {
    type: "checkbox",
    required: false,
    title: "Is Paid?",
    inputType: "",
    options: "",
    field: "",
  },

  payment_method: {
    type: "select",
    required: false,
    title: "Payment Method",
    inputType: "",
    options: ["CASH", "UPI", "CARD", "ONLINE"],
    field: "",
  },

  services: {
    type: "related",
    required: false,
    title: "Services",
    inputType: "",
    options: "",
    field: "name",
    multiple: true,
    parent_field: "service",
  },
  products: {
    type: "related",
    required: false,
    title: "Parts",
    inputType: "",
    options: "",
    field: "name",
    multiple: true,
    parent_field: "product",
  },
  status_check: {
    type: "divider",
    title: "Status Update Type",
  },
  status_jobcard_open: {
    type: "checkbox",
    required: false,
    title: "Job Card Open",
    inputType: "",
    options: "",
    field: "",
  },
  status_technician_assigned: {
    type: "checkbox",
    required: false,
    title: "Status Technician Assigned",
    inputType: "",
    options: "",
    field: "",
  },
  status_start_route: {
    type: "checkbox",
    required: false,
    title: "Status Start Route",
    inputType: "",
    options: "",
    field: "",
  },
  status_arrived_route: {
    type: "checkbox",
    required: false,
    title: "Status Arrived Route",
    inputType: "",
    options: "",
    field: "",
  },
  status_estimate_sent: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Sent",
    inputType: "",
    options: "",
    field: "",
  },
  status_estimate_approved: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Approved",
    inputType: "",
    options: "",
    field: "",
  },
  status_estimate_rejected: {
    type: "checkbox",
    required: false,
    title: "Status Estimate Rejected",
    inputType: "",
    options: "",
    field: "",
  },
  status_payment_collected: {
    type: "checkbox",
    required: false,
    title: "Status Payment Collected",
    inputType: "",
    options: "",
    field: "",
  },
  status_closed_jobcard: {
    type: "checkbox",
    required: false,
    title: "Status Closed Jobcard",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  customer: "",
  mechanic: "",
  mechanic_assigned: false,
  description: "",
  booking_date: "",
  bike: "",

  bike_year: "",
  bike_no: "",
  engine_type: "",
  address: "",
  city: "",
  state: "",
  pin: "",
  latitude: "",
  longitude: "",
  coupon: "",
  sub_total: "",
  tax: "",
  discount: "",
  total: "",
  is_coupon_applied: false,
  jobcard_open_date: "",
  jobcard_close_date: "",
  status: "",
  estimate_status: "",
  is_paid: false,
  payment_method: "",
  serivces: [],
  parts: [],
};

export const view_all_table = [
  {
    name: "Customer",
    value: "customer",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Customer Phone",
    value: "customer",
    related: true,
    field: "phone",
    image: false,
  },
  {
    name: "Mechanic",
    value: "mechanic",
    related: true,
    field: "name",
    image: false,
  },
  {
    name: "Booking Date",
    value: "booking_date",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Bike Image",
    value: "bike",
    related: true,
    field: "image",
    image: true,
  },
  {
    name: "Bike No",
    value: "bike_no",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Total",
    value: "total",
    related: "",
    field: "",
    image: false,
    preFilled: "₹",
  },
  {
    name: "Status",
    value: "status",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Estimate Status",
    value: "estimate_status",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Is Paid",
    value: "is_paid",
    related: "",
    field: "",
    image: false,
    checkbox: true,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    field: "mechanic",
    label: "Mechanic",
    type: "related",
  },
  {
    field: "estimate_status",
    label: "Estimate Status",
    type: "string",
  },
];
