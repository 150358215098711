import api from "../../domain/api";
import {
  GET_TRANSACTIONS_STATED,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_ENDED,
  ADD_TRANSACTION_STATED,
  ADD_TRANSACTION,
  ADD_TRANSACTION_ENDED,
  EDIT_TRANSACTION_STATED,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_ENDED,
  GET_TRANSACTION_STATED,
  GET_TRANSACTION,
  GET_TRANSACTION_ENDED,
  GET_ALL_TRANSACTIONS_STATED,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_ENDED,
} from "../types/transaction_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addTransaction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TRANSACTION_STATED,
    });
    const { data } = await api.post(`/transactions`, formData);
    dispatch({
      type: ADD_TRANSACTION,
      payload: data,
    });
    dispatch({
      type: ADD_TRANSACTION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_TRANSACTION_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getTransactions =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_TRANSACTIONS_STATED,
      });

      const { data } = await api.get(
        `/transactions?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_TRANSACTIONS,
        payload: data,
      });
      dispatch({
        type: GET_TRANSACTIONS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getTransaction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTION_STATED,
    });
    const { data } = await api.get(`/transactions/${id}`);

    dispatch({
      type: GET_TRANSACTION,
      payload: data,
    });
    dispatch({
      type: GET_TRANSACTION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_TRANSACTION_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editTransaction = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_TRANSACTION_STATED,
    });
    const { data } = await api.put(`/transactions/${id}`, formData);
    dispatch({
      type: EDIT_TRANSACTION,
      payload: data,
    });
    dispatch({
      type: EDIT_TRANSACTION_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_TRANSACTION_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteTransaction = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/transactions/${id}`);
    dispatch(setAlert("Transaction Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllTransactions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_TRANSACTIONS_STATED,
    });
    const { data } = await api.get(`/transactions/all`);

    dispatch({
      type: GET_ALL_TRANSACTIONS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_TRANSACTIONS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_TRANSACTIONS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
