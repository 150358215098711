import api from "../../domain/api";
import {
  GET_BIKES_STATED,
  GET_BIKES,
  GET_BIKES_ENDED,
  ADD_BIKE_STATED,
  ADD_BIKE,
  ADD_BIKE_ENDED,
  EDIT_BIKE_STATED,
  EDIT_BIKE,
  EDIT_BIKE_ENDED,
  GET_BIKE_STATED,
  GET_BIKE,
  GET_BIKE_ENDED,
  GET_ALL_BIKES_STATED,
  GET_ALL_BIKES,
  GET_ALL_BIKES_ENDED,
} from "../types/bike_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addBike = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BIKE_STATED,
    });
    const { data } = await api.post(`/bikes`, formData);
    dispatch({
      type: ADD_BIKE,
      payload: data,
    });
    dispatch({
      type: ADD_BIKE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_BIKE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getBikes =
  ({ pageNumber = "", start_from = "", start_to = "", queryParams }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BIKES_STATED,
      });

      const { data } = await api.get(
        `/bikes?&pageNumber=${pageNumber}&start_from=${start_from}&start_to=${start_to}${queryParams}`
      );

      dispatch({
        type: GET_BIKES,
        payload: data,
      });
      dispatch({
        type: GET_BIKES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_BIKES_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getBike = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BIKE_STATED,
    });
    const { data } = await api.get(`/bikes/${id}`);

    dispatch({
      type: GET_BIKE,
      payload: data,
    });
    dispatch({
      type: GET_BIKE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_BIKE_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editBike = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BIKE_STATED,
    });
    const { data } = await api.put(`/bikes/${id}`, formData);
    dispatch({
      type: EDIT_BIKE,
      payload: data,
    });
    dispatch({
      type: EDIT_BIKE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_BIKE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBike = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/bikes/${id}`);
    dispatch(setAlert("Bike Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllBikes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BIKES_STATED,
    });
    const { data } = await api.get(`/bikes/all`);

    dispatch({
      type: GET_ALL_BIKES,
      payload: data,
    });
    dispatch({
      type: GET_ALL_BIKES_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BIKES_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
