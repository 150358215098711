import { useState, useEffect, useCallback } from "react";
import _debounce from "lodash/debounce";

import { useSelector, useDispatch } from "react-redux";
import {
  addService,
  getServices,
  getService,
  editService,
  deleteService,
  getAllServices,
} from "../../store/actions/service_action";

// Get All Data
export const useAllServices = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  useEffect(() => {
    async function allQuery() {
      const params = new URLSearchParams(window.location.search);

      const paramsObj = Array.from(params.keys()).reduce(
        (acc, val) => ({ ...acc, [val]: params.get(val) }),
        {}
      );
      let newQParams = ``;
      Object.keys(paramsObj) &&
        Object.keys(paramsObj).map((item) => {
          newQParams += `&search[${item}]=${paramsObj[item]}`;
        });
      if (deleteEntry) {
        await dispatch(deleteService(deleteEntry));
      }
      await dispatch(
        getServices({
          pageNumber,
          start_from: dateFrom,
          start_to: dateTo,
          queryParams: newQParams,
        })
      );
    }
    allQuery();
  }, [deleteEntry, pageNumber, dateFrom, dateTo, window.location.search]);
  useEffect(() => {
    setPageNumber(1);
  }, [dateTo, dateFrom]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data,
    setPageNumber,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    deleteBtnClicked,
  ];
};

// Get Single Data
export const useSingleService = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(getService(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateService = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service);
  const addData = async (data) => {
    await dispatch(addService(data));
  };
  return [data, addData];
};
export const useUpdateService = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.service);
  const updateData = async (id, data) => {
    await dispatch(editService(id, data));
  };
  return [updateData];
};

export const useSelectAllService = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(getAllServices());
  }, []);
  return [data];
};
