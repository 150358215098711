import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";

import AddBrand from "./containers/brands/AddBrand";
import AllBrands from "./containers/brands/AllBrands";
import ViewBrand from "./containers/brands/ViewBrand";
import EditBrand from "./containers/brands/EditBrand";

import AddBike from "./containers/bikes/AddBike";
import AllBikes from "./containers/bikes/AllBikes";
import ViewBike from "./containers/bikes/ViewBike";
import EditBike from "./containers/bikes/EditBike";

import AddHomepage from "./containers/homepages/AddHomepage";
import AllHomepages from "./containers/homepages/AllHomepages";
import ViewHomepage from "./containers/homepages/ViewHomepage";
import EditHomepage from "./containers/homepages/EditHomepage";

import AddCategory from "./containers/categorys/AddCategory";
import AllCategorys from "./containers/categorys/AllCategorys";
import ViewCategory from "./containers/categorys/ViewCategory";
import EditCategory from "./containers/categorys/EditCategory";

import AddLocation from "./containers/locations/AddLocation";
import AllLocations from "./containers/locations/AllLocations";
import ViewLocation from "./containers/locations/ViewLocation";
import EditLocation from "./containers/locations/EditLocation";

import AddService from "./containers/services/AddService";
import AllServices from "./containers/services/AllServices";
import ViewService from "./containers/services/ViewService";
import EditService from "./containers/services/EditService";

import AddProduct from "./containers/products/AddProduct";
import AllProducts from "./containers/products/AllProducts";
import ViewProduct from "./containers/products/ViewProduct";
import EditProduct from "./containers/products/EditProduct";

import AddCoupon from "./containers/coupons/AddCoupon";
import AllCoupons from "./containers/coupons/AllCoupons";
import ViewCoupon from "./containers/coupons/ViewCoupon";
import EditCoupon from "./containers/coupons/EditCoupon";

import AddJobcard from "./containers/jobcards/AddJobcard";
import AllJobcards from "./containers/jobcards/AllJobcards";
import ViewJobcard from "./containers/jobcards/ViewJobcard";
import EditJobcard from "./containers/jobcards/EditJobcard";

import AddTransaction from "./containers/transactions/AddTransaction";
import AllTransactions from "./containers/transactions/AllTransactions";
import ViewTransaction from "./containers/transactions/ViewTransaction";
import EditTransaction from "./containers/transactions/EditTransaction";

import AddCustomer from "./containers/customers/AddCustomer";
import AllCustomers from "./containers/customers/AllCustomers";
import ViewCustomer from "./containers/customers/ViewCustomer";
import EditCustomer from "./containers/customers/EditCustomer";

import AddMechanic from "./containers/mechanics/AddMechanic";
import AllMechanics from "./containers/mechanics/AllMechanics";
import ViewMechanic from "./containers/mechanics/ViewMechanic";
import EditMechanic from "./containers/mechanics/EditMechanic";

import AddComplaint from "./containers/complaints/AddComplaint";
import AllComplaints from "./containers/complaints/AllComplaints";
import ViewComplaint from "./containers/complaints/ViewComplaint";
import EditComplaint from "./containers/complaints/EditComplaint";

import AddBanner from "./containers/banners/AddBanner";
import AllBanners from "./containers/banners/AllBanners";
import ViewBanner from "./containers/banners/ViewBanner";
import EditBanner from "./containers/banners/EditBanner";

import AddOffer from "./containers/offers/AddOffer";
import AllOffers from "./containers/offers/AllOffers";
import ViewOffer from "./containers/offers/ViewOffer";
import EditOffer from "./containers/offers/EditOffer";

import AddFooter from "./containers/footers/AddFooter";
import AllFooters from "./containers/footers/AllFooters";
import ViewFooter from "./containers/footers/ViewFooter";
import EditFooter from "./containers/footers/EditFooter";

import AddBannerlogo from "./containers/bannerlogos/AddBannerlogo";
import AllBannerlogos from "./containers/bannerlogos/AllBannerlogos";
import ViewBannerlogo from "./containers/bannerlogos/ViewBannerlogo";
import EditBannerlogo from "./containers/bannerlogos/EditBannerlogo";

import Profile from "./containers/profile/Profile";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem("token");
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />
          <PrivateRoutes exact path="/brands" component={AllBrands} />
          <PrivateRoutes exact path="/brands/add" component={AddBrand} />
          <PrivateRoutes exact path="/brands/:id/view" component={ViewBrand} />
          <PrivateRoutes exact path="/brands/:id/edit" component={EditBrand} />

          <PrivateRoutes exact path="/bikes" component={AllBikes} />
          <PrivateRoutes exact path="/bikes/add" component={AddBike} />
          <PrivateRoutes exact path="/bikes/:id/view" component={ViewBike} />
          <PrivateRoutes exact path="/bikes/:id/edit" component={EditBike} />
          <PrivateRoutes exact path="/homepages" component={EditHomepage} />
          <PrivateRoutes exact path="/homepages/add" component={AddHomepage} />
          <PrivateRoutes
            exact
            path="/homepages/:id/view"
            component={ViewHomepage}
          />
          <PrivateRoutes
            exact
            path="/homepages/:id/edit"
            component={EditHomepage}
          />

          <PrivateRoutes exact path="/categories" component={AllCategorys} />
          <PrivateRoutes exact path="/categories/add" component={AddCategory} />
          <PrivateRoutes
            exact
            path="/categories/:id/view"
            component={ViewCategory}
          />
          <PrivateRoutes
            exact
            path="/categories/:id/edit"
            component={EditCategory}
          />

          <PrivateRoutes exact path="/locations" component={AllLocations} />
          <PrivateRoutes exact path="/locations/add" component={AddLocation} />
          <PrivateRoutes
            exact
            path="/locations/:id/view"
            component={ViewLocation}
          />
          <PrivateRoutes
            exact
            path="/locations/:id/edit"
            component={EditLocation}
          />
          <PrivateRoutes exact path="/services" component={AllServices} />
          <PrivateRoutes exact path="/services/add" component={AddService} />
          <PrivateRoutes
            exact
            path="/services/:id/view"
            component={ViewService}
          />
          <PrivateRoutes
            exact
            path="/services/:id/edit"
            component={EditService}
          />

          <PrivateRoutes exact path="/products" component={AllProducts} />
          <PrivateRoutes exact path="/products/add" component={AddProduct} />
          <PrivateRoutes
            exact
            path="/products/:id/view"
            component={ViewProduct}
          />
          <PrivateRoutes
            exact
            path="/products/:id/edit"
            component={EditProduct}
          />
          <PrivateRoutes exact path="/coupons" component={AllCoupons} />
          <PrivateRoutes exact path="/coupons/add" component={AddCoupon} />
          <PrivateRoutes
            exact
            path="/coupons/:id/view"
            component={ViewCoupon}
          />
          <PrivateRoutes
            exact
            path="/coupons/:id/edit"
            component={EditCoupon}
          />
          <PrivateRoutes exact path="/jobcards" component={AllJobcards} />
          <PrivateRoutes exact path="/jobcards/add" component={AddJobcard} />
          <PrivateRoutes
            exact
            path="/jobcards/:id/view"
            component={ViewJobcard}
          />
          <PrivateRoutes
            exact
            path="/jobcards/:id/edit"
            component={EditJobcard}
          />
          <PrivateRoutes
            exact
            path="/transactions"
            component={AllTransactions}
          />
          <PrivateRoutes
            exact
            path="/transactions/add"
            component={AddTransaction}
          />
          <PrivateRoutes
            exact
            path="/transactions/:id/view"
            component={ViewTransaction}
          />
          <PrivateRoutes
            exact
            path="/transactions/:id/edit"
            component={EditTransaction}
          />

          <PrivateRoutes exact path="/customers" component={AllCustomers} />
          <PrivateRoutes exact path="/customers/add" component={AddCustomer} />
          <PrivateRoutes
            exact
            path="/customers/:id/view"
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path="/customers/:id/edit"
            component={EditCustomer}
          />

          <PrivateRoutes exact path="/mechanics" component={AllMechanics} />
          <PrivateRoutes exact path="/mechanics/add" component={AddMechanic} />
          <PrivateRoutes
            exact
            path="/mechanics/:id/view"
            component={ViewMechanic}
          />
          <PrivateRoutes
            exact
            path="/mechanics/:id/edit"
            component={EditMechanic}
          />

          <PrivateRoutes exact path="/complaints" component={AllComplaints} />
          <PrivateRoutes
            exact
            path="/complaints/add"
            component={AddComplaint}
          />
          <PrivateRoutes
            exact
            path="/complaints/:id/view"
            component={ViewComplaint}
          />
          <PrivateRoutes
            exact
            path="/complaints/:id/edit"
            component={EditComplaint}
          />

          <PrivateRoutes exact path="/banners" component={AllBanners} />
          <PrivateRoutes exact path="/banners/add" component={AddBanner} />
          <PrivateRoutes
            exact
            path="/banners/:id/view"
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path="/banners/:id/edit"
            component={EditBanner}
          />

          <PrivateRoutes exact path="/offers" component={AllOffers} />
          <PrivateRoutes exact path="/offers/add" component={AddOffer} />
          <PrivateRoutes exact path="/offers/:id/view" component={ViewOffer} />
          <PrivateRoutes exact path="/offers/:id/edit" component={EditOffer} />

          <PrivateRoutes exact path="/footers" component={AllFooters} />
          <PrivateRoutes exact path="/footers/add" component={AddFooter} />
          <PrivateRoutes
            exact
            path="/footers/:id/view"
            component={ViewFooter}
          />
          <PrivateRoutes
            exact
            path="/footers/:id/edit"
            component={EditFooter}
          />

          <PrivateRoutes exact path="/bannerlogos" component={AllBannerlogos} />
          <PrivateRoutes
            exact
            path="/bannerlogos/add"
            component={AddBannerlogo}
          />
          <PrivateRoutes
            exact
            path="/bannerlogos/:id/view"
            component={ViewBannerlogo}
          />
          <PrivateRoutes
            exact
            path="/bannerlogos/:id/edit"
            component={EditBannerlogo}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
