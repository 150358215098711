export const PAGE_TITLE = "Products";
export const PAGE_SINGLE_TITLE = "Product";
export const LINK_URL = "products";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
    options: "",
    field: "",
  },
  name_hindi: {
    type: "string",
    required: true,
    title: "Name (Hindi)",
    inputType: "text",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
  product_code: {
    type: "string",
    required: true,
    title: "Product Code",
  },
  regular_price: {
    type: "string",
    required: true,
    title: "Regular Price",
    inputType: "number",
    options: "",
    field: "",
  },
  sale_price: {
    type: "string",
    required: true,
    title: "Sale Price",
    inputType: "number",
    options: "",
    field: "",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  description_hindi: {
    type: "html",
    required: false,
    title: "Description (Hindi)",
    inputType: "",
    options: "",
    field: "",
  },
  category: {
    type: "related",
    required: false,
    title: "Category",
    inputType: "",
    options: "",
    field: "name",
  },
  bikes: {
    type: "related",
    required: false,
    multiple: true,
    title: "Bike",
    inputType: "",
    options: "",
    field: "name",
    parent_field: "bike",
  },
  tax: {
    type: "string",
    required: false,
    title: "Tax",
    inputType: "number",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
  name_hindi: "",
  image: "",
  product_code: "",
  regular_price: "",
  sale_price: "",
  description: "",
  description_hindi: "",
  category: "",
  bikes: "",
  tax: "",
};

export const view_all_table = [
  {
    name: "Name",
    value: "name",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Name(Hindi)",
    value: "name_hindi",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Product Code",
    value: "product_code",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Image",
    value: "image",
    related: "",
    field: "",
    image: true,
  },
  {
    name: "Regular Price",
    value: "regular_price",
    related: "",
    field: "",
    image: false,
  },
  {
    name: "Sale Price",
    value: "sale_price",
    related: "",
    field: "",
    image: false,
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "product_code",
    field: "product_code",
    label: "Product Code",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "regular_price",
    field: "regular_price",
    label: "Regular Price",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "sale_price",
    field: "sale_price",
    label: "Sale Price",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "tax",
    field: "tax",
    label: "Tax",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
