import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/bikes_enum";
import { useUpdateBike, useSingleBike } from "../../shared/hooks/UseBike";
import { useSelectAllBrand } from "../../shared/hooks/UseBrand";

const EditBike = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleBike(match.params.id);
  const [updateData] = useUpdateBike();
  const { bike_loading, bike, edit_bike_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  //  const [bike1] = useSelectAllBike();
  const [brands] = useSelectAllBrand();

  const [dropdownOptions, setDropdownOptions] = useState(null);
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(bike._id, data);
    history.push(`/${LINK_URL}/${bike._id}/view`);
  };

  // useEffect(() => {
  //   if (bike1 && bike1.all_bikes) {
  //     const newData = bike1.all_bikes.map((item) => {
  //       return { label: item.bike_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_bike: newData });
  //   }
  // }, [bike1]);
  useEffect(() => {
    if (brands && brands.all_brands) {
      const newData = brands.all_brands.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, brand: newData });
    }
  }, [brands]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!bike_loading ? (
              bike && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {bike[Object.keys(inputFields)[0]]} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${bike._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={bike}
                    edit={true}
                    featuredImage={featuredImage}
                    gallery={gallery}
                    setFeaturedImage={setFeaturedImage}
                    setGallery={setGallery}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_bike_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBike;
