export const GET_LOCATIONS_STATED = "GET_LOCATIONS_STATED";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_ENDED = "GET_LOCATIONS_ENDED";
export const ADD_LOCATION_STATED = "ADD_LOCATION_STARTED";
export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_ENDED = "ADD_LOCATION_ENDED";
export const EDIT_LOCATION_STATED = "EDIT_LOCATION_STATED";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const EDIT_LOCATION_ENDED = "EDIT_LOCATION_ENDED";
export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_STATED = "GET_LOCATION_STATED";
export const GET_LOCATION_ENDED = "GET_LOCATION_ENDED";
export const RESET_LOCATION = "RESET_LOCATION";
export const ERROR_LOCATION = "ERROR_LOCATION";
export const GET_ALL_LOCATIONS_STATED = "GET_ALL_LOCATIONS_STATED";
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_ALL_LOCATIONS_ENDED = "GET_ALL_LOCATIONS_ENDED";
