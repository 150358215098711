import {
  GET_TRANSACTIONS_STATED,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_ENDED,
  ADD_TRANSACTION_STATED,
  ADD_TRANSACTION,
  ADD_TRANSACTION_ENDED,
  EDIT_TRANSACTION_STATED,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_ENDED,
  GET_TRANSACTION_STATED,
  GET_TRANSACTION,
  GET_TRANSACTION_ENDED,
  GET_ALL_TRANSACTIONS_STATED,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_ENDED
} from "../types/transaction_type";

const initialState = {
  transactions_loading: true,
  transactions: null,
  page: null,
  pages: null,
  total_transactions: 0,

  transaction: null,
  transaction_loading: null,

  loading: true,

  transaction_message: null,
  all_transactions: null,
  all_transactions_loading: null,
  add_transaction_loading: true,
  edit_transaction_loading: true
};

export const transaction_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TRANSACTIONS_STATED:
      return {
        ...state,
        transactions: null,
        pages: null,
        page: null,
        total_transactions: 0,
        transactions_loading: true
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload.transactions,
        pages: payload.pages,
        page: payload.page,
        total_transactions: payload.count
      };
    case GET_TRANSACTIONS_ENDED:
      return {
        ...state,
        transactions_loading: false
      };
    case GET_ALL_TRANSACTIONS_STATED:
      return {
        ...state,
        all_transactions_loading: true,
        all_transactions: null
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        all_transactions: payload
      };
    case GET_ALL_TRANSACTIONS_ENDED:
      return {
        ...state,
        all_transactions_loading: false
      };

    case ADD_TRANSACTION_STATED:
      return {
        ...state,
        transaction_message: null,
        add_transaction_loading: true
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        transaction_message: payload
      };
    case ADD_TRANSACTION_ENDED:
      return {
        ...state,
        add_transaction_loading: false
      };
    case GET_TRANSACTION_STATED:
      return {
        ...state,
        transaction: null,
        transaction_loading: true
      };
    case GET_TRANSACTION:
      return {
        ...state,
        transaction: payload
      };
    case GET_TRANSACTION_ENDED:
      return {
        ...state,
        transaction_loading: false
      };
    case EDIT_TRANSACTION_STATED:
      return {
        ...state,
        transaction_message: null,
        edit_transaction_loading: true
      };
    case EDIT_TRANSACTION:
      return {
        ...state,
        transaction_message: payload
      };
    case EDIT_TRANSACTION_ENDED:
      return {
        ...state,
        edit_transaction_loading: false
      };

    default:
      return state;
  }
};
