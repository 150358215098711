import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/mechanics_enum";
import { useCreateMechanic } from "../../shared/hooks/UseMechanic";
// import { useSelectAllMechanic } from "../../shared/hooks/UseMechanic";

const AddMechanic = ({}) => {
  let history = useHistory();
  const [mechanic, addData] = useCreateMechanic();
  const { add_mechanic_loading } = mechanic;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  // const [mechanic] = useSelectAllMechanic();

  const [dropdownOptions, setDropdownOptions] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  // useEffect(() => {
  //   if (mechanic && mechanic.all_mechanics) {
  //     const newData = mechanic.all_mechanics.map((item) => {
  //       return { label: item.mechanic_name, value: item._id };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, parent_mechanic: newData });
  //   }
  // }, [mechanic]);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_mechanic_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMechanic;
